import { useEffect, useState } from "react";
import { approveScore, getAllScores, rejectScore, Score, setScoreState, updateScore } from "../supabase";
import { AdjustScoreDialog, ScoreRow, ScoreTable } from "./utils";

export const Scores = () => {
    const defaultRow: Score = {
        created_at: '',
        id: '',
        modified: '',
        name: '',
        state: "approved",
        timezone: '',
        score: 0, 
        game: {
            id: '',
            name: ''
        },
        level: {
            order: 0,
            id: '',
            name: ''
        },
        score_type: {
            id: '',
            name: '',
            score_type: 'default'
        }
    };
    const [approved, setApproved] = useState<Score[]>([]);
    const [pending, setPending] = useState<Score[]>([]);
    const [rejected, setRejected] = useState<Score[]>([]);
    const [adjustOpen, setAdjustOpen] = useState(false);
    const [adjustRow, setAdjustRow] = useState<Score>(defaultRow);

    // TODO make this less gross
    const getScores = () => {
        getAllScores().then(value => {
            if (value) {
                const approved = value.filter(score => score.state === "approved");
                const pending = value.filter(score => score.state === "pending");
                const rejected = value.filter(score => score.state === "rejected");
                setApproved(approved);
                setPending(pending);
                setRejected(rejected);
            }
        });
    };

    useEffect(() => {
        getScores();
    }, []);

    const handleAdjust = (row: any) => {
        setAdjustRow(row);
        setAdjustOpen(true);
    };

    const handleAdjustClose = () => {
        setAdjustOpen(false);
        setAdjustRow(defaultRow);
    }

    const onAdjustChange = (row: any) => {
        updateScore(row.id, { name: row.name, score: row.score }).then(() => {
            getScores();
        });
        setAdjustOpen(false);
    };

    const handleApprove = (id: string) => {
        approveScore(id).then(() => {
            getScores();
        });
    };

    const handleDisapprove = (id: string) => {
        setScoreState(id, 'pending').then(() => {
            getScores();
        });
    };

    const handleReject = (id: string) => {
        rejectScore(id).then(() => {
            getScores();
        });
    };

    return (
        <>
            <h2>Scores</h2>
            <div>
                <h3>Pending scores</h3>
                <ScoreTable>
                    {pending.map((row: any) => (
                        <ScoreRow
                            key={row.id}
                            row={row}
                            onApprove={handleApprove}
                            onDisapprove={handleDisapprove}
                            onReject={handleReject}
                            onAdjust={handleAdjust} />
                    ))}
                </ScoreTable>
            </div>
            <div>
                <h3>Approved scores</h3>
                <ScoreTable>
                    {approved.map((row: any) => (
                        <ScoreRow
                            key={row.id}
                            row={row}
                            onApprove={handleApprove}
                            onDisapprove={handleDisapprove}
                            onReject={handleReject}
                            onAdjust={handleAdjust} />
                    ))}
                </ScoreTable>
            </div>
            <div>
                <h3>Rejected scores</h3>
                <ScoreTable>
                    {rejected.map((row: any) => (
                        <ScoreRow
                            key={row.id}
                            row={row}
                            onApprove={handleApprove}
                            onDisapprove={handleDisapprove}
                            onReject={handleReject}
                            onAdjust={handleAdjust} />
                    ))}
                </ScoreTable>
            </div>
            <AdjustScoreDialog row={adjustRow} onChange={onAdjustChange} open={adjustOpen} onClose={handleAdjustClose} />
        </>
    )
};