import { useEffect, useState } from "react";
import { allGamesScoreTypesAndLevels, GameDetails } from "../supabase";

const Game = ({game}: {game: GameDetails}) => {
    return (
        <div>
            <span>{game.name}</span>
            <div>
                {game.levels.map((level, index) => <span key={level.id}>
                    {level.name}
                    {index === game.levels.length - 1 ? '' : ', '}
                </span>)}
            </div>
            <div>
                {game.score_types.map((scoreType, index) =>
                    <span key={scoreType.id}>
                        {scoreType.name} (<code>{scoreType.score_type}</code>)
                        {index === game.score_types.length - 1 ? '' : ', '}
                    </span>)}
            </div>
        </div>    
    );
};

export const Games = () => {
    const [games, setGames] = useState<GameDetails[]>([]);
    useEffect(() => {
        allGamesScoreTypesAndLevels().then(data => {
            if (data) {
                setGames(data);
            }
        });
    }, []);

    return (
        <>
            <h2>Games</h2>
            {games.map(game => <Game game={game} key={game.id}/>)}
        </>
    );
}