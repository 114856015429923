import { splitEvery } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import './HighScores.css';
import { ScoreTable } from './ScoreTable';
import { GameRecord, groupScores, LevelRecord, ScoreTypeRecord } from './score_utils';
import { topScores } from './supabase';

const ScoreTypeCard = ({scoreType}: {scoreType: ScoreTypeRecord}) => {
  return (
    <div className="score-type-card">
      <h3>{scoreType.name}</h3>
      <ScoreTable scores={scoreType.scores} scoreType={scoreType.type}/>
    </div>
  )
};

const LevelCard = ({level}: {level: LevelRecord}) => {
  return (
    <div className="level-card">
      <h2>{level.name}</h2>
      {level.scoreTypes.map(type => <ScoreTypeCard key={type.id} scoreType={type}/>)}
    </div>
  )
};

const GameCard = ({game}: {game: GameRecord}) => {
  game.levels.sort((a, b) => a.order - b.order);
  return (
    <div className="game-card">
      <h1>{game.name}</h1>
      <div className="score-cards">
        {game.levels.map(level => <LevelCard key={level.id} level={level}/>)}
      </div>
    </div>
  );
}

const useDisplaySequence = (gamesAndScores: Map<string, GameRecord>): [GameRecord, () => void] => {
  const [index, setIndex] = useState(0);
  const seqence = useMemo(() => {
    const seqence = [];
    for (const entry of gamesAndScores.entries()) {
      const game = entry[1];
      game.levels.sort((a, b) => a.order - b.order);
      for (const levelGroup of splitEvery(4, game.levels)) {
        seqence.push({...game, levels: levelGroup});
      }
    }
    return seqence;
  }, [gamesAndScores]);

  const next = () => {
    if (index < seqence.length - 1) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  }

  return [seqence[index], next];
}

export const HighScores = () => {
  const [gamesAndScores, setGamesAndScores] = useState<Map<string, GameRecord>>(new Map());
  const [display, next] = useDisplaySequence(gamesAndScores);

  useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [next]);

  useEffect(() => {
    topScores().then(data => {
      if (data) {
        const games = groupScores(data);
        setGamesAndScores(games);
      }
    });
  }, []);

  const getDisplay = () => {
    if (!display) {
      return null;
    }
    return <GameCard key={display.id} game={display}/>
  }
  
  return (
    <main>
      <div className="scores">
        {getDisplay()}
      </div>
    </main>
  );
};
