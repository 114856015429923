import { createClient } from "@supabase/supabase-js";
import { useLocation, Navigate } from "react-router-dom";
import { formatScore } from "./score_utils";

const supabaseUrl = "https://npyowheyvzulfjgehxfy.supabase.co";
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYzNjc3ODk2NCwiZXhwIjoxOTUyMzU0OTY0fQ.wVyAfDprm-pB6jtZqTee2WF7wNauEHZKIL7XlAqe1U0";

const supabase = createClient(supabaseUrl, supabaseKey);

export const useAuth = () => {
    return {
        session() {
            return supabase.auth.session();
        },
        hasSession() {
            return !!supabase.auth.session();
        },
        signOut() {
            return supabase.auth.signOut();
        },
        signIn(email: string, password: string) {
            return supabase.auth.signIn({
                email,
                password
            }); 
        }
    };
};

export const RequireAuth = ({children}: any) => {
    const { hasSession } = useAuth();
    const location = useLocation();
    
    if (!hasSession()) {
        return <Navigate to="/login" state={{ from: location }}/>
    }

    return children;
};

export type ScoreType = 'default' | 'race_time';

export interface Game {
    id: string;
    created: string;
    name: string;
}

export interface ScoreCategory {
    id: string;
    name: string;
    score_type: ScoreType;
}

export interface Level {
    id: string;
    name: string;
    order: number;
}

export interface GameDetails extends Game {
    score_types: ScoreCategory[];
    levels: Level[];
}



export const allGames = async (): Promise<Game[]> => {
    const { data, error } = await supabase.from('games').select('*');
    if (error) {
        console.error("error", error);
    }
    return data ?? [];
};

export const allGamesScoreTypesAndLevels = async (): Promise<GameDetails[]> => {
    const { data, error } = await supabase.from('games')
    .select(`
        id,
        name,
        score_types!score_types_game_id_fkey (
            id,
            name,
            score_type
        ),
        levels!levels_game_id_fkey (
            id,
            name,
            order
        )
    `);
    if (error) {
        console.error("error", error);
    }
    return data ?? [];
};

export const allGamesWithScores = async (): Promise<(Game & { scores: Score[]})[]> => {
    const { data, error } = await supabase.from('games').select(`
        *,
        scores ( * )
    `).order('score', { ascending: false, nullsFirst: false, foreignTable: 'scores'});
    if (error) {
        console.error("error", error);
    }
    return data ?? [];
};

export const gamesWithApprovedScores = async (): Promise<(Game & { scores: Score[]})[]> => {
    const { data, error } = await supabase.from('games').select(`
        *,
        scores ( * )
    `)
    .eq('scores.state', 'approved')
    .order('score', { ascending: false, nullsFirst: false, foreignTable: 'scores'});
    if (error) {
        console.error("error", error);
    }
    return data ?? [];
};

export interface GroupedScoreRecord {
    score_id: string;
    name: string;
    score: number;
    game_id: string;
    game_name: string;
    level_id: string;
    level_name: string;
    level_order: number;
    score_type_id: string;
    score_type_name: string;
    score_type: ScoreType;
    score_count: number;
    level_count: number;
}

export const topScores = async (): Promise<GroupedScoreRecord[]> => {
    const { data } = await supabase.rpc('grouped_scores');

    return data ?? [];
};

export type ScoreState = 'approved' | 'pending' | 'rejected';

export interface Score {
    id: string;
    name: string;
    score: number;
    game: Pick<Game, 'id' | 'name'>;
    score_type: ScoreCategory;
    level: Level;
    state: ScoreState;
    created_at: string;
    modified: string;
    timezone: string;
}

const getScores = async (state: ScoreState) => {
    return supabase.from('scores').select(`
        id,
        name,
        score,
        state,
        game:game_id ( id, name ),
        score_type:score_type_id ( id, name, score_type ),
        level:level_id ( id, name ),
        created_at,
        modified,
        timezone
    `).eq('state', state);
};

export const getAllScores = async (): Promise<Score[]> => {
    const { data, error } = await supabase.from('scores').select(`
        id,
        name,
        score,
        state,
        game:game_id ( id, name ),
        score_type:score_type_id ( id, name, score_type ),
        level:level_id ( id, name ),
        created_at,
        modified,
        timezone
    `).order('created_at', { ascending: false });
    if (error) {
        console.error(error);
    }
    return data ?? [];
}

export const pendingScores = async () => {
    const { data, error } = await getScores('pending');
    if (error) {
        console.error("error", error);
    }
    return data;
};

export const approvedScores = async () => {
    const { data, error } = await getScores('approved');
    if (error) {
        console.error("error", error);
    }
    return data;
};

export const rejectedScores = async () => {
    const { data, error } = await getScores('rejected');
    if (error) {
        console.error("error", error);
    }
    return data;
};

export const addScore = async ({name, score, gameId, levelId, scoreTypeId}: any) => {
    const params = {
        name,
        score,
        game_id: gameId,
        level_id: levelId,
        score_type_id: scoreTypeId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    const { data, error } = await supabase.rpc('add_score', params);
    if (data) {
        try {
            const [game, scoreType] = await Promise.all([
                supabase.from('games').select('name').eq('id', gameId).single(),
                supabase.from('score_types').select('score_type').eq('id', scoreTypeId).single()
            ]);
            if (game) {
                data[0].game_name = game.data.name;
                data[0].score = formatScore(data[0].score, scoreType.data.score_type);
            }
        } catch (error) {
            console.error('error fetching additional details', error);
        }

        let path = `https://${window.location.hostname}/discord`;
        if (window.location.hostname.includes('localhost')) {
            path = 'http://localhost:8788/discord';
        }
        fetch(path, {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch(error => console.error('error posting webhook', error));
    }
    if (error) {
        console.error("error", error);
    }
    return data;
};

export const setScoreState = async (id: string, state: 'approved' | 'pending' | 'rejected') => {
    return supabase.from('scores').update({state}).eq('id', id);
};

export const approveScore = async (id: string) => {
    return setScoreState(id, 'approved');
};

export const rejectScore = async (id: string) => {
    return setScoreState(id, 'rejected');
};

export const updateScore = async (id: string, values: {name?: string, score?: number}) => {
    return supabase.from('scores').update(values).eq('id', id);
};
