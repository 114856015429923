import React, { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";

export interface RootOutletContext {
    title: string;
    useTitle: (title: string) => void;
}

export const Root = () => {
    const [title, setTitle] = useState('High Scores');
    const useTitle = (title: string) => {
        useEffect(() => {
            setTitle(title);
        });
    }
    return (
        <main>
            <header className="nav-header">
                <h1>{title}</h1>
                <div className="nav-links">
                    <NavLink to="/" className={({isActive}) => isActive ? 'active' : ''}>Home</NavLink>
                    <NavLink to="add" className={({isActive}) => isActive ? 'active' : ''}>Add Score</NavLink>
                </div>
            </header>
            <Outlet context={{title, useTitle}}/>
        </main>
    );
};