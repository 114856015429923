import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import "./AddScore.css";
import { RootOutletContext } from "./Root";
import { formatScoreString, parseScore } from "./score_utils";
import { addScore, allGamesScoreTypesAndLevels, GameDetails, Level, ScoreCategory, ScoreType } from "./supabase";

const getPlaceholder = (scoreType: ScoreType) => {
    if (scoreType === 'race_time') {
        return `0'00''000`;
    }
    return '1,000';
}

const getValidationPattern = (scoreType: ScoreType) => {
    if (scoreType === 'race_time') {
        return `[0-9]+'[0-9]+''[0-9]+`;
    }
    return `[0-9]*`;
}

export const ScoreInput = ({onChange, score, scoreType}: {
    onChange: (value: string) => void,
    score: string,
    scoreType: ScoreType
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replaceAll(/\D/g, '');
        onChange(value);
    };

    return (
        <label>
            <span>Score</span>
            <input 
                placeholder={getPlaceholder(scoreType)} 
                pattern={getValidationPattern(scoreType)}
                maxLength={100}
                type="text" 
                required 
                value={formatScoreString(score, scoreType)} onChange={handleChange}/>
        </label>
    );
};

const Select = ({label, defaultLabel, value, onChange, children}: any) => {
    return (
        <label>
            <span>{label}</span>
            <select required value={value} onChange={onChange}>
                <option disabled value="default">{defaultLabel}</option>
                {children}
            </select>
        </label>
    );
};

export const AddScore = () => {
    const [games, setGames] = useState<GameDetails[]>([]);
    const [successVisible, setSuccessVisible] = useState(false);
    const [game, setGame] = useState<GameDetails | null>(null);
    const [category, setCategory] = useState<ScoreCategory | null>(null);
    const [level, setLevel] = useState<Level | null>(null);
    const [name, setName] = useState("");
    const [score, setScore] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(false);
    const form = useRef<HTMLFormElement>(null);
    const { useTitle } = useOutletContext<RootOutletContext>();
    useTitle('Add a new high score!');
    
    useEffect(() => {
        allGamesScoreTypesAndLevels().then(data => setGames(data));
    }, []);

    const resetForm = () => {
        form.current?.reset();
        setGame(null);
        setCategory(null);
        setLevel(null);
        setName("");
        setScore("");
    };

    const handleSuccess = () => {
        setSuccessVisible(true);
        resetForm();
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setDisableSubmit(true);
        console.log(game, level, category);
        addScore({
            name,
            score: parseScore(score, category?.score_type ?? 'default'),
            gameId: game?.id,
            levelId: level?.id,
            scoreTypeId: category?.id
        }).then(data => {
            if (data) {
                handleSuccess();
            }
        }).finally(() => {
            setDisableSubmit(false);
        });
    };

    const handleChooseGame = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const id = event.target.value;
        const game = games.find(game => game.id === id) ?? null;
        setGame(game);
        if (game) {
            setLevel(game.levels[0]);
            setCategory(game.score_types[0]);
            game.levels.sort((a, b) => a.order - b.order);
        }
    };

    const handleChooseLevel = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const id = event.target.value;
        setLevel(game?.levels.find(level => level.id === id) ?? null);
    };

    const handleChooseCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const id = event.target.value;
        setCategory(game?.score_types.find(type => type.id === id) ?? null);
    };

    return (
        <>
            <div hidden={!successVisible}>
                <p>Score submitted!</p>
                <p>We'll review it and let you know when it's live!</p>
            </div>
            <form onSubmit={handleSubmit} ref={form}>
                <Select label='Game' defaultLabel='Select a game' value={game?.id ?? 'default'} onChange={handleChooseGame}>
                    {games.map((game: any) => {
                        return <option key={game.id} value={game.id}>{game.name}</option>
                    })}
                </Select>
                {(game?.levels.length ?? 0) > 1 && (
                    <Select label='Level' defaultLabel='Select a level' value={level?.id ?? 'default'} onChange={handleChooseLevel}>
                        {game?.levels.map((level: any) => {
                            return <option key={level.id} value={level.id}>{level.name}</option>
                        })}
                    </Select>)}
                {(game?.score_types.length ?? 0) > 1 && (
                    <Select label='Category' defaultLabel='Select a category' value={category?.id ?? 'default'} onChange={handleChooseCategory}>
                        {game?.score_types.map((scoreType: any) => {
                            return <option key={scoreType.id} value={scoreType.id}>{scoreType.name}</option>
                        })}
                    </Select>)}
                <label>
                    <span>Name</span>
                    <input placeholder="jet" type="text" required value={name} onChange={event => setName(event.target.value)}/>
                </label>
                <ScoreInput onChange={value => setScore(value)} score={score} scoreType={category?.score_type ?? 'default'}/>
                <label>
                    <span>Email (optional)</span>
                    <input placeholder="you@gmail.com" type="email" />
                </label>
                <label>
                    <span>Phone (optional)</span>
                    <input placeholder="(705) 123-4567" type="tel" />
                </label>
                <button type="submit" disabled={disableSubmit}>Submit</button>
            </form>
        </>
    );
};