import { GroupedScoreRecord, ScoreType } from "./supabase";

export interface ScoreRecord {
    id: string;
    name: string;
    score: number;
}

export const getScore = (row: GroupedScoreRecord): ScoreRecord => {
    return {
        id: row.score_id,
        name: row.name,
        score: row.score
    };
};

export interface ScoreTypeRecord {
    id: string;
    name: string;
    type: ScoreType;
    scores: ScoreRecord[];
}

export const getScoreType = (row: GroupedScoreRecord): ScoreTypeRecord => {
    return {
        id: row.score_type_id,
        name: row.score_type_name,
        type: row.score_type,
        scores: [
            getScore(row)
        ]
    };
};

export interface LevelRecord {
    id: string;
    name: string;
    order: number;
    scoreTypes: ScoreTypeRecord[];
}

export const getLevel = (row: GroupedScoreRecord): LevelRecord => {
    return {
        id: row.level_id,
        name: row.level_name,
        order: row.level_order,
        scoreTypes: [
            getScoreType(row)
        ]
    };
};

export interface GameRecord {
    id: string;
    name: string;
    levels: LevelRecord[];
}

export const getGame = (row: GroupedScoreRecord): GameRecord => {
    return {
        id: row.game_id,
        name: row.game_name,
        levels: [
            getLevel(row)
        ]
    };
};

export type GameMap = Map<string, GameRecord>;

export const groupScores = (rows: GroupedScoreRecord[]): Map<string, GameRecord> => {
    const games = new Map<string, GameRecord>();
    for (const row of rows) {
        if (!games.has(row.game_id)) {
            games.set(row.game_id, getGame(row));
        } else {
            const game = games.get(row.game_id);
            const level = game?.levels.find((level: any) => level.id === row.level_id);
            if (level) {
                const score_type = level.scoreTypes.find((type: any) => type.id === row.score_type_id);
                if (score_type) {
                    score_type.scores.push(getScore(row));
                } else {
                    level.scoreTypes.push(getScoreType(row));
                }
            } else {
                game?.levels.push(getLevel(row));
            }
        }
    }
    return games;
}

export const formatScoreString = (score: string, scoreType: ScoreType): string => {
    score = score.replaceAll(/\D/g, '');
    if (scoreType === 'race_time') {
        return score.split('').map((letter, index) => {
            if (
                (score.length <= 6 && index === 1)
                || (score.length > 6 && index === 2)
            ) {
                return `'${letter}`;
            }
            if (
                (score.length <= 6 && index === 3)
                || (score.length > 6 && index === 4)
            ) {
                return `''${letter}`
            }
            return letter;
        }).join('');
    }
    return score;
};

export const raceTimeToMilliseconds = (time: string): number => {
    time = formatScoreString(time, 'race_time');
    if (!time.includes(`'`) && !time.includes(`''`)) {
        return 0;
    }
    const [rest, millisecondString] = time.split(`''`);
    const [minuteString, secondString] = rest.split(`'`);
    const minutes = parseInt(minuteString, 10);
    const seconds = parseInt(secondString, 10);
    const milliseconds = parseInt(millisecondString, 10);
    return minutes * 60_000 + seconds * 1_000 + milliseconds;
}

export const millisecondsToRaceTime = (ms: number): string => {
    if (ms <= 0) {
        return '';
    }
    const minutes = ms / 60_000 | 0;
    const seconds = (ms - minutes * 60_000) / 1_000 | 0;
    const milliseconds = ms - (minutes * 60_000) - (seconds * 1_000);
    return `${minutes}'${seconds.toString().padStart(2, '0')}''${milliseconds.toString().padStart(3, '0')}`;
}

export const parseScore = (score: string, scoreType: ScoreType): number => {
    if (scoreType === 'race_time') {
        return raceTimeToMilliseconds(score);
    }
    return parseInt(score, 10);
}

export const formatScore = (score: number, scoreType: ScoreType): string => {
    if (scoreType === 'race_time') {
        return millisecondsToRaceTime(score);
    }
    return formatScoreString(score.toString(), 'default');
}
