import React from 'react';
import { render } from 'react-dom';
import './index.css';
import { HighScores } from './HighScores';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AddScore } from './AddScore';
import { Admin } from './admin/Admin';
import { Login } from './Login';
import { Scores } from './admin/Scores';
import { Games } from './admin/Games';
import { Home } from './Home';
import { Root } from './Root';

render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Root/>}>
          <Route path="/" element={<Home/>}/>
          <Route path="/add" element={<AddScore/>}/>
          <Route path="/add-score" element={<AddScore />}/>
        </Route>
        <Route path="/display" element={<HighScores/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/admin" element={<Admin/>}>
          <Route path="scores" element={<Scores/>}/>
          <Route path="games" element={<Games/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
