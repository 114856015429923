import { formatScore, ScoreRecord } from "./score_utils";
import { ScoreType } from "./supabase";
import "./ScoreTable.css";

export const ScoreRow = ({score, type, index}: any) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{score.name}</td>
      <td>{formatScore(score.score, type)}</td>
    </tr>
  )
};

export interface ScoreTableProps {
    scores: ScoreRecord[];
    scoreType: ScoreType;
}
export const ScoreTable = ({scores, scoreType}: ScoreTableProps) => {
    return (
        <table className="score-table" cellSpacing="0" cellPadding="0">
            <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Score</th>
            </tr>
            </thead>
            <tbody>
            {scores.map((score: any, index: number) => {
                return <ScoreRow key={score.id} index={index} score={score} type={scoreType}/>
            })}
            </tbody>
        </table>
    );
};