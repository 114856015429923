import { DependencyList, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { RootOutletContext } from "./Root";
import { GameMap, GameRecord, groupScores, LevelRecord } from "./score_utils";
import { topScores } from "./supabase";
import "./Home.css";
import defaultPhoto from "./arcade_stock.jpeg";
import { ScoreTable } from "./ScoreTable";
import { clamp } from "ramda";

const useAsyncEffect = (fn: () => Promise<void>, deps: DependencyList | undefined = undefined) => {
    useEffect(() => {
        const result = fn();
        return () => {
            result.then((cleanup: any | undefined) => {
                if (cleanup) {
                    cleanup();
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

const LevelCell = ({level}: {level: LevelRecord}) => {
    const getClassName = () => {
        const span = clamp(1, 2, level.scoreTypes.length);
        return `game-row-cell cell-span-${span}`;
    }
    return (
        <div className={getClassName()}>
            <h3>{level.name}</h3>
            <div className="game-row-cell_score-type">
                {level.scoreTypes.map(scoreType => (
                    <div className="game-row-cell_score-type_score">
                        <p>{scoreType.name}</p>
                        <ScoreTable key={scoreType.id} scores={scoreType.scores} scoreType={scoreType.type}/>
                    </div>
                ))}
            </div>
        </div>
    );
};

const GameRow = ({game}: {game: GameRecord}) => {
    game.levels.sort((a, b) => a.order - b.order);
    return (
        <div className="game-row">
            <div className="game-row-cell">
                <h2>{game.name}</h2>
                <div className="arcade-machine_image" style={{
                    backgroundImage: `url(${defaultPhoto})`
                }}></div>
            </div>
            {game.levels.map(level => <LevelCell key={level.id} level={level}/>)}
        </div>
    );
};

export const Home = () => {
    const [games, setGames] = useState<GameMap>(new Map());
    const {useTitle} = useOutletContext<RootOutletContext>();
    useTitle('High Scores');

    useAsyncEffect(async () => {
        const data = await topScores();
        const games = groupScores(data);
        setGames(games);
        console.log(games);
    }, []);
    return (
        <>
            {Array.from(games.entries()).map(([key, game]) => <GameRow key={key} game={game}/>)}
        </>
    );
}