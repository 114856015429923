import { MouseEventHandler, useEffect, useRef, useState } from "react";
import dialogPolyfill from "dialog-polyfill";
import "dialog-polyfill/dist/dialog-polyfill.css";
import { Score } from "../supabase";
import { utcToZonedTime } from "date-fns-tz";
import { ScoreInput } from "../AddScore";
import { formatScore, parseScore } from "../score_utils";

export interface AdjustScoreDialogProps {
    row: Score;
    onChange: (event: Score) => void;
    onClose: () => void;
    open: boolean;
}

export const AdjustScoreDialog = ({ row, onChange, onClose, open }: AdjustScoreDialogProps) => {
    const dialog = useRef<any>(null);
    const [name, setName] = useState("");
    const [score, setScore] = useState("");

    useEffect(() => {
        dialogPolyfill.registerDialog(dialog.current as any);

        const keydownHandler = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && open && dialog.current) {
                dialog.current?.close();
                onClose();
            }
        }

        document.addEventListener("keydown", keydownHandler);

        if (open && !dialog.current?.open) {
            dialog.current?.showModal();
            setName(row.name);
            setScore(formatScore(row.score, row.score_type.score_type));
        } else if (!open && dialog.current?.open) {
            dialog.current?.close();
        }

        return () => {
            document.removeEventListener('keydown', keydownHandler);
        };
    }, [open, row.name, row.score, row.score_type.score_type, onClose]);

    const handleCancel = () => {
        dialog.current?.close();
        onClose();
    };

    const handleApply: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        onChange({ ...row, name, score: parseScore(score, row.score_type.score_type) });
    };
    return (
        <dialog ref={dialog}>
            <h3>Adjust score</h3>
            <form method="dialog">
                <label>
                    <span>Name</span>
                    <input type="text" value={name} onChange={event => setName(event.target.value)} />
                </label>
                <ScoreInput score={score} scoreType={row.score_type.score_type} onChange={value => setScore(value)}/>
                <button type="reset" onClick={handleCancel}>Cancel</button>
                <button type="submit" onClick={handleApply}>Apply</button>
            </form>
        </dialog>
    );
};

export interface ScoreRowProps {
    row: Score;
    onApprove: (id: string) => void;
    onDisapprove: (id: string) => void;
    onReject: (id: string) => void;
    onAdjust: (row: Score) => void;
}

export const ScoreRow = ({ row, onApprove, onDisapprove, onReject, onAdjust }: ScoreRowProps) => {
    const showApprove = (state: string) => state === "pending" || state === "rejected";
    const showDisapprove = (state: string) => state === "approved";
    const showReject = (state: string) => state !== "rejected";
    const created = utcToZonedTime(row.created_at, row.timezone);
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;
    const formatter = new Intl.DateTimeFormat(locale, { dateStyle: 'long', timeStyle: 'long' });
    return (
        <tr>
            <td>{row.game.name}</td>
            <td>{row.level.name}</td>
            <td>{row.score_type.name}</td>
            <td>{row.name}</td>
            <td>{formatScore(row.score, row.score_type.score_type)}</td>
            <td><code>{row.state}</code></td>
            <td>{formatter.format(created)}</td>
            <td>
                {showDisapprove(row.state) && <button className="approve" onClick={() => onDisapprove(row.id)}>Disapprove</button>}
                {showApprove(row.state) && <button className="approve" onClick={() => onApprove(row.id)}>Approve</button>}
                {showReject(row.state) && <button className="reject" onClick={() => onReject(row.id)}>Reject</button>}
                <button onClick={() => onAdjust(row)}>Adjust</button>
            </td>
        </tr>
    );
};

export const ScoreTable = ({ children }: any) => {
    return (
        <table className="admin-table">
            <thead>
                <tr>
                    <th>Game</th>
                    <th>Level</th>
                    <th>Score Type</th>
                    <th>Name</th>
                    <th>Score</th>
                    <th>State</th>
                    <th>Submitted</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    );
};