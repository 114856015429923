import "./Admin.css";
import { RequireAuth, useAuth } from "../supabase";
import { matchPath, NavLink, Outlet, useLocation, useNavigate,  } from "react-router-dom";
import { useEffect } from "react";

export const Admin = () => {
    const { signOut } = useAuth();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut().then(({error}) => {
            if (error) {
                console.error(error);
            }
            navigate('/login');
        })
    };

    const location = useLocation();
    useEffect(() => {
        if (matchPath('admin', location.pathname)) {
            navigate('scores');
        }
    });
    
    return (
        <RequireAuth>
            <main>
                <header className="nav-header">
                    <h1>Admin</h1>
                    <div className="nav-links">
                        <NavLink to="scores" className={({isActive}) => isActive ? 'active' : ''}>Scores</NavLink>
                        <NavLink to="games" className={({isActive}) => isActive ? 'active' : ''}>Games</NavLink>
                    </div>
                    <button className="nav-button" type="button" onClick={handleLogout}>Logout</button>
                </header>
                <Outlet/>
            </main>
        </RequireAuth>
    );
};