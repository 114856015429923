import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./supabase";

export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorText, setErrorText] = useState("");
    const { signIn } = useAuth();
    const nagivate = useNavigate();
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        signIn(email, password).then(auth => {
            if (auth.error) {
                console.error(auth.error);
                setErrorText(auth.error.message);
                return;
            }
            nagivate('/admin');
        });
    };

    return (
        <main>
            <h1>Login</h1>
            {!!errorText ? <p>{errorText}</p> : null}
            <form onSubmit={handleSubmit}>
                <label>
                    <span>Email</span>
                    <input type="text" required value={email} onChange={event => setEmail(event.target.value)} />
                </label>
                <label>
                    <span>Password</span>
                    <input type="password" required value={password} onChange={event => setPassword(event.target.value)}/>
                </label>
                <button type="submit">Login</button>
            </form>
        </main>
    )
};